// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

.PhoneInputInput{
    font: inherit !important;
    letter-spacing: inherit !important;
    color: currentColor !important;
    padding: 4px 0 5px !important;
    border: 0 !important;
    box-sizing: content-box !important;
    background: none !important;
    height: 1.4375em !important;
    margin: 0 !important;
    -webkit-tap-highlight-color: transparent !important;
    display: block !important;
    min-width: 0 !important;
    width: 100% !important;
    -webkit-animation-name: mui-auto-fill-cancel !important;
    animation-name: mui-auto-fill-cancel !important;
    -webkit-animation-duration: 10ms !important;
    animation-duration: 10ms !important;
    color: #121926 !important;
    padding: 16.5px 14px !important;
    font-weight: 500 !important;
    background: #F8FAFC !important;
    padding: 15.5px 14px !important;
    border-radius: 12px ;
}
.react-tel-input .form-control {
    width: 100% !important;
    height: 55px !important;
    border-radius:12px !important;
    background: #F8FAFC !important;

}
.Login_countryCode__9v5lt{
    height:45px;width:45px;
    padding:0px;
}

.fr-er-404 .fr-er-content h2 {
    font-size: 40px;
    color: #242424;
    margin: 30px 0;
}
.fr-er-404 .fr-er-content h2 span {
    color: #fe696a;
}
.fr-er-404 {
    margin: 0 auto;
    display: table;
    text-align: center;
}

.fr-er-404 .fr-er-content p {
    width: 80%;
    font-size: 12px;
    margin: 0 auto;
    line-height: 1.8;
    color: crimson;
}

/*--thank you pop starts here--*/
.thank-you-pop{
	width:100%;
 	padding:20px;
	text-align:center;
}
.thank-you-pop img{
	width:76px;
	height:auto;
	margin:0 auto;
	display:block;
	margin-bottom:25px;
}

.thank-you-pop h1{
	font-size: 42px;
    margin-bottom: 25px;
	color:#5C5C5C;
}
.thank-you-pop p{
	font-size: 20px;
    margin-bottom: 27px;
 	color:#5C5C5C;
}
.thank-you-pop h3.cupon-pop{
	font-size: 25px;
    margin-bottom: 40px;
	color:#222;
	display:inline-block;
	text-align:center;
	padding:10px 20px;
	border:2px dashed #222;
	clear:both;
	font-weight:normal;
}
.thank-you-pop h3.cupon-pop span{
	color:#03A9F4;
}
.thank-you-pop a{
	display: inline-block;
    margin: 0 auto;
    padding: 9px 20px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    background-color: #8BC34A;
    border-radius: 17px;
}
.thank-you-pop a i{
	margin-right:5px;
	color:#fff;
}
#ignismyModal .modal-header{
    border:0px;
}

student-profile .card {
    border-radius: 10px;
  }
  
  .student-profile .card .card-header .profile_img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 10px auto;
    border: 10px solid #ccc;
    border-radius: 50%;
  }
  
  
/*--thank you pop ends here--*/
.overlay {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: #000;
    visibility: hidden;
  }
  
  .overlay-open .overlay {
    visibility: visible;
    opacity: 0.5;
  }
  
  .user-pro-list:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 80px;
    background-size: cover;
    background-position: center;
    opacity: 0.9;
  }
  
  .user-pro-list .avatar {
    border: 3px solid #fff;
    top: 10px;
    margin-bottom: 10px;
  }
  
  .user-pro-list .avatar-status {
    right: 8px;
    bottom: 2px;
  }
  
  .project-description li:before {
    content: "";
    margin-right: 8px;
    position: relative;
    font-family: "FontAwesome" !important;
    opacity: 0.6;
    font-size: 16px;
  }
  
  .project-description li {
    padding: 5px;
    font-size: 14px;
  }
  
  .app.sidebar-mini .btn-tableview {
    position: absolute;
    right: 20%;
    z-index: 999;
  }
  
  .btn-tableview {
    position: absolute;
    right: 25%;
    z-index: 999;
  }
  
  .client-perm-table .custom-control.success {
    display: inline-block;
  }
  
  .admisetting-tabs .nav-link {
    padding: 16px 20px 16px 20px;
    border-left: 3px solid transparent;
    position: relative;
  }
  
  .admisetting-tabs .nav-link.active {
    background: #dee5f7;
    color: var(--primary);
    border-left: 3px solid var(--primary);
  }
  
  .admisetting-tabs .nav-link:last-child {
    border-bottom: 0;
  }
  
  @media (min-width: 992px) {
    .admisetting-tabs .nav-link.active::before {
      position: absolute;
      top: 0;
      bottom: 0;
      content: "";
      right: -15px;
      border-top: 26px solid transparent;
      border-left: 15px solid #dee5f7;
      border-bottom: 26px solid transparent;
    }
  }
  .enable-autoclose,
  .enable-paypal {
    display: none;
    transition: all 0.3s ease;
    padding: 0.75rem;
    border: 1px solid #d3dfea;
    border-radius: 5px;
    margin-bottom: 1.5rem;
  }
  
  .add-autoclose .enable-autoclose,
  .add-paypal .enable-paypal {
    display: block;
    transition: all 0.3s ease;
  }
  
  .enable-autoclose .input-group-addon,
  .adminsetting-content .input-group-addon {
    color: #9ba5ca;
    border-left: 0 !important;
    border: 1px solid #d3dfea;
    border-radius: 0 5px 5px 0;
    font-size: 0.9375rem;
  }
  
  .admisetting-tabs .nav-icon {
    font-size: 22px;
    margin-right: 8px;
  }
  
  .enable-oldtoken {
    display: none;
    transition: all 0.3s ease;
  }
  
  .add-oldtoken .enable-oldtoken {
    display: block;
    transition: all 0.3s ease;
  }
  
  .role-table .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 0;
  }
  
  .role-table .access-icon {
    font-size: 16px;
  }
  
  #chartline1 {
    height: 330px !important;
  }
  
  .table-regcompany table.dataTable tbody th,
  .table-regcompany table.dataTable tbody td,
  .table-regcompany table.dataTable thead th,
  .table-regcompany table.dataTable thead td {
    padding: 10px 22px !important;
  }
  
  .enable-smtpemail,
  .open-paypal {
    display: none;
    transition: all 0.3s ease;
  }
  
  .add-smtpemail .enable-smtpemail,
  .add-paypal .open-paypal {
    display: block;
    transition: all 0.3s ease;
  }
  
  #superrole-list th {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  
  .icon-style-circle1 {
    width: 30px;
    height: 30px;
    line-height: 30px !important;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    border: 1px solid #e6ebf1;
    margin-right: 8px;
  }
  
  .icon-style-circle1:hover {
    background: #f3f4f7;
  }
  
  .file-manger .icons {
    width: 30px;
    height: 30px !important;
    border-radius: 5px;
    background: transparent;
    text-align: center;
    line-height: 30px !important;
    display: block !important;
    padding: 0 !important;
    font-size: 16px;
    color: #565b95 !important;
    border: 1px solid #e6ebf1;
    margin-right: 8px;
  }
  
  .file-manger .icons:hover {
    background: #f3f4f7;
  }
  
  .header-style1,
  .header-style3 {
    background: #fff !important;
  }
  
  .header-style1 .header-brand-img.dark-logo,
  .header-style3 .header-brand-img.dark-logo {
    display: none;
  }
  
  .header-style2 .header-brand-img.desktop-lgo {
    display: none;
  }
  
  .item-image {
    position: relative;
  }
  
  .item-image .badge {
    position: absolute;
    top: 15px;
    left: 15px;
  }
  
  .product-overly a {
    width: 100%;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease;
    color: #fff;
    padding: 5px 0;
    text-align: center;
    background: #000;
  }
  
  .item-image:hover .product-overly a {
    opacity: 1;
  }
  
  .product-links {
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    top: 15px;
    right: 7px;
  }
  
  .product-links li {
    transform: translateX(60px);
    transition: all 0.3s ease 0.3s;
  }
  
  .product-links li a {
    background: #fff;
    font-size: 14px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin: 0 0 7px;
    border-radius: 50px;
    display: block;
    transition: all 0.3s ease 0s;
    text-align: center;
  }
  
  .item-image:hover .product-links li {
    transform: translateX(0);
  }
  
  .shop-title {
    font-size: 16px;
    font-weight: 500;
  }
  
  .cardprice span.type--strikethrough {
    opacity: 0.5;
    text-decoration: line-through;
    font-size: 14px;
    margin-right: 5px;
  }
  
  .cardprice span {
    font-size: 20px;
    font-weight: 600;
  }
  
  #hr-table .custom-control-label-md::before,
  #assigntask .custom-control-label-md::before {
    left: 0;
  }
  #hr-table .custom-control-label-md::after,
  #assigntask .custom-control-label-md::after {
    left: 0;
  }

  /*------ Default -------*/
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
    border: 0;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(196, 205, 224, 0.2);
    border-radius: 13px;
  }
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  
  .card-subtitle {
    margin-top: -0.75rem;
    margin-bottom: 0;
  }
  
  .card-text:last-child {
    margin-bottom: 0;
  }
  
  .card-link:hover {
    text-decoration: none;
  }
  .card-link + .card-link {
    margin-left: 1.5rem;
  }
  
  .card .card-header .card-title:before {
    content: "";
    position: absolute;
    left: 0px;
    padding: 2px;
    height: 25px;
    background: var(--primary);
  }
  
  .card-header {
    background: transparent;
    display: flex;
    align-items: center;
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
    margin-bottom: 0;
    border-bottom: 1px solid #e6ebf1;
  }
  .card-header .card-title {
    margin-bottom: 0;
  }
  .card-header:first-child {
    border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
  }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0;
  }
  
  .card-footer {
    padding: 1.5rem 1.5rem;
    color: #4b5986;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid #e6ebf1;
  }
  .card-footer:last-child {
    border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
  }
  
  .card-header-tabs {
    margin-right: -0.75rem;
    margin-bottom: -1.5rem;
    margin-left: -0.75rem;
    border-bottom: 0;
    margin: -1.25rem 0;
    line-height: 2rem;
  }
  .card-header-tabs .nav-item {
    margin-bottom: 1px;
  }
  
  .card-header-pills {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    margin: -0.75rem 0;
  }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
  }
  
  .card-img {
    width: 100%;
    border-radius: calc(3px - 1px);
  }
  
  .card-img-top {
    width: 100%;
    border-top-left-radius: calc(3px - 1px);
    border-top-right-radius: calc(3px - 1px);
  }
  
  .card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(3px - 1px);
    border-bottom-left-radius: calc(3px - 1px);
  }
  
  .card-deck {
    display: flex;
    flex-direction: column;
  }
  .card-deck .card {
    margin-bottom: 0.75rem;
  }
  
  .card-group {
    display: flex;
    flex-direction: column;
  }
  .card-group > .card {
    margin-bottom: 0.75rem;
  }


  /*------ Tables -----*/
.table, .text-wrap table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
  
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e6ebf1;
  }
  
  .text-wrap table th, .text-wrap table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e6ebf1;
  }
  
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 0;
  }
  
  .border {
    vertical-align: top;
    border: 1px solid #e6ebf1;
  }
  
  .table tbody + tbody, .text-wrap table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  
  .table .table, .text-wrap table .table, .table .text-wrap table {
    background-color: #ebeff8;
  }
  
  .text-wrap .table table, .text-wrap table table {
    background-color: #ebeff8;
  }
  
  .table-sm th, .table-sm td {
    padding: 0.3rem;
  }
  
  .table-bordered, .text-wrap table, .table-bordered th, .text-wrap table th, .table-bordered td, .text-wrap table td {
    border: 1px solid #e6ebf1;
  }
  
  .table-bordered thead th, .text-wrap table thead th, .table-bordered thead td, .text-wrap table thead td {
    border-bottom-width: 2px;
  }
  
  .table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody {
    border: 0;
  }
  
  .table-striped tbody tr:nth-of-type(odd), .table-hover tbody tr:hover {
    background-color: #f3f5fb;
  }
  
  .table-hover tbody tr:hover {
    background-color: #f3f5fb !important;
    cursor: pointer;
  }
  
  .table-primary {
    background-color: rgba(51, 102, 255, 0.1);
    border-radius: 0 0 5px 5px;
  }
  .table-primary > th, .table-primary > td {
    background-color: rgba(51, 102, 255, 0.1);
  }
  
  .table-primary.table-vcenter th, .table-primary.table-vcenter td {
    border-color: rgba(68, 84, 195, 0.17) !important;
  }
  
  .table-hover .table-primary:hover {
    background-color: #ebeff8;
  }
  .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
    background-color: #b7cded;
  }
  
  .table tr.selected {
    background-color: #f3f5fb;
  }
  
  .table-secondary {
    background-color: var(--secondary);
    border-radius: 0 0 5px 5px;
  }
  .table-secondary > th, .table-secondary > td {
    background-color: var(--secondary);
  }
  
  .table-hover .table-secondary:hover {
    background-color: #cfd2d6;
  }
  .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
    background-color: var(--secondary);
  }
  
  .table-success {
    background-color: #d2ecb8;
    border-radius: 0 0 5px 5px;
  }
  .table-success > th, .table-success > td {
    background-color: #d2ecb8;
  }
  
  .table-hover .table-success:hover {
    background-color: #c5e7a4;
    border-radius: 0 0 5px 5px;
  }
  .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
    background-color: #c5e7a4;
  }
  
  .table-info {
    background-color: #cbe7fb;
    border-radius: 0 0 5px 5px;
  }
  .table-info > th, .table-info > td {
    background-color: #cbe7fb;
  }
  
  .table-hover .table-info:hover {
    background-color: #b3dcf9;
  }
  .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
    background-color: #b3dcf9;
  }
  
  .table-warning {
    background-color: #fbeebc;
    border-radius: 0 0 5px 5px;
  }
  .table-warning > th, .table-warning > td {
    background-color: #fbeebc;
  }
  
  .table-warning.table-vcenter th, .table-warning.table-vcenter td {
    border-color: rgba(236, 180, 3, 0.17) !important;
  }
  
  .table-hover .table-warning:hover {
    background-color: #fae8a4;
  }
  .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
    background-color: #fae8a4;
  }
  
  .table-danger {
    background-color: #f1c1c0;
    border-radius: 0 0 5px 5px;
  }
  .table-danger > th, .table-danger > td {
    background-color: #f1c1c0;
  }
  
  .table-hover .table-danger:hover {
    background-color: #ecacab;
  }
  .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
    background-color: #ecacab;
  }
  
  .table-light {
    background-color: #fdfdfe;
    border-radius: 0 0 5px 5px;
  }
  .table-light > th, .table-light > td {
    background-color: #fdfdfe;
  }
  
  .table-hover .table-light:hover {
    background-color: #ececf6;
  }
  .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
    background-color: #ececf6;
  }
  
  .table-dark {
    background-color: #c6c8ca;
    border-radius: 0 0 5px 5px;
  }
  .table-dark > th, .table-dark > td {
    background-color: #c6c8ca;
  }
  
  .table-hover .table-dark:hover {
    background-color: #b9bbbe;
  }
  .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
    background-color: #b9bbbe;
  }
  
  .table-active {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .table-active > th, .table-active > td {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .table .thead-dark th, .text-wrap table .thead-dark th {
    color: #ebeff8;
    background-color: #212529;
    border-color: #32383e;
  }
  
  .table .thead-light th, .text-wrap table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  
  .table-dark {
    color: #ebeff8;
    background-color: #212529;
  }
  .table-dark th, .table-dark td, .table-dark thead th {
    border-color: #32383e;
  }
  .table-dark.table-bordered {
    border: 0;
  }
  
  .text-wrap table.table-dark {
    border: 0;
  }
  
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
  }

  .card-img-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
  }
  
  .reg {
    text-align: center;
    font-size: 50px;
    color: #2e1170;
    float: right;
  }
  
  .card-title {
    line-height: 1.2;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.01rem;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
  .card-title a {
    color: inherit;
  }
  .card-title:only-child {
    margin-bottom: 0;
  }
  .card-title small {
    color: #9aa0ac;
    font-size: 0.875rem;
    display: block;
    margin: -0.75rem 0 1rem;
    line-height: 1.1;
    font-weight: 400;
  }
  
  .card-subtitle {
    color: #9aa0ac;
    font-size: 0.875rem;
    display: block;
    margin: -0.75rem 0 1rem;
    line-height: 1.1;
    font-weight: 400;
  }
  
  .card-table {
    margin-bottom: 0;
  }
  .card-table tr:first-child td, .card-table tr:first-child th {
    border-top: 0;
  }
  .card-table tr td:first-child, .card-table tr th:first-child {
    padding-left: 1.5rem;
  }
  .card-table tr td:last-child, .card-table tr th:last-child {
    padding-right: 1.5rem;
  }
  
  .card-body + .card-table {
    border-top: 1px solid #e6ebf1;
  }
  
  .card-profile .card-header {
    height: 5rem;
    background-size: cover;
    border: 0;
  }
  
  .profie-img .flex-md-row img {
    width: 60px;
  }
  
  .card-profile-img {
    max-width: 6rem;
    margin-bottom: 1rem;
    border: 3px solid #fff;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  
  .card-link + .card-link {
    margin-left: 1rem;
  }
  
  .card-body + .card-list-group {
    border-top: 1px solid #e6ebf1;
  }
  
  .card-list-group .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .card-list-group .list-group-item:last-child {
    border-bottom: 0;
  }
  .card-list-group .list-group-item:first-child {
    border-top: 0;
  }
  
  .card-aside {
    flex-direction: row;
  }
  
  .card-aside-column {
    min-width: 5rem;
    width: 20%;
    flex: 0 0 20%;
    background: no-repeat center/cover;
  }
  
  .card-value {
    font-size: 1rem;
    line-height: 3.4rem;
    height: 3.4rem;
    display: flex;
    align-items: center;
    font-weight: 400;
  }
  .card-value i {
    vertical-align: middle;
  }
  
  .card-chart-bg {
    height: 4rem;
    margin-top: -1rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  
  .card-options {
    margin-left: auto;
    display: flex;
    order: 100;
    margin-right: -0.5rem;
    color: #9aa0ac;
    align-self: center;
  }
  .card-options a:not(.btn) {
    color: #263871;
    display: inline-block;
    min-width: 1rem;
  }
  .card-options a:not(.btn):hover {
    text-decoration: none;
    color: #263871;
  }
  .card-options a:not(.btn) i {
    font-size: 1rem;
    vertical-align: middle;
  }
  .card-options .dropdown-toggle:after {
    display: none;
  }
  
  .widgets-cards .wrp.icon-circle {
    width: 73px;
    height: 73px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: 15px;
  }
  .widgets-cards .wrp.icon-circle i {
    font-size: 1.6rem;
    color: #fff;
  }
  .widgets-cards .wrp p {
    margin-bottom: 0;
    line-height: 1;
    margin-bottom: 10px;
  }
  .widgets-cards .wrp p:first-child {
    font-weight: 400;
    font-size: 36px;
  }
  
  ul li .legend-dots {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 0.5rem;
  }
  
  .legend li {
    padding: 5px;
    float: left;
  }
  
  .stretch-card {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
  }
  .stretch-card > .card {
    width: 100%;
    min-width: 100%;
  }
  
  .icon-size {
    font-size: 2.5rem !important;
  }
  
  .card-img-absolute {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
  
  .statistics-box {
    position: relative;
    padding-left: 80px;
    text-align: right;
    padding-top: 14px;
    min-height: 80px;
    text-align: center;
  }
  .statistics-box .ico {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 80px;
    height: 80px;
    font-size: 70px;
    line-height: 80px;
    text-align: center;
  }
  .statistics-box .ico.small {
    font-weight: 900;
    font-size: 42px;
  }
  .statistics-box h2 {
    font-weight: 600 !important;
  }
  
  /*Card options*/
  .card-collapsed > :not(.card-header):not(.card-status) {
    display: none;
  }
  .card-collapsed .card-options-collapse i:before {
    content: "";
  }
  
  .card-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin: 0;
  }
  .card-fullscreen .card-options-fullscreen i:before {
    content: "";
  }
  .card-fullscreen .card-options-remove {
    display: none;
  }
  
  /*Card maps*/
  .card-map {
    height: 15rem;
    background: #e9ecef;
  }
  
  .card-map-placeholder {
    background: no-repeat center;
  }
  
  /**Card tabs*/
  .card-tabs {
    display: flex;
  }
  
  .card-tabs-bottom .card-tabs-item {
    border: 0;
  }
  .card-tabs-bottom .card-tabs-item.active {
    border-top-color: #fff;
  }
  
  .card-tabs-item {
    flex: 1 1 auto;
    display: block;
    padding: 1.4rem 1.5rem;
    color: inherit;
    overflow: hidden;
  }
  
  a.card-tabs-item {
    background: #fafbfc;
  }
  a.card-tabs-item:hover {
    text-decoration: none;
    color: inherit;
  }
  a.card-tabs-item:focus {
    z-index: 1;
  }
  a.card-tabs-item.active {
    background: #fff;
    border-bottom-color: #fff;
  }
  
  /**Card status*/
  .card-status {
    position: absolute;
    top: -1px;
    left: 0px;
    right: 0px;
    height: 3px;
    border-radius: 7px 7px 0 0;
    background: rgba(0, 40, 100, 0.12);
  }
  
  .card-status-left {
    right: auto;
    bottom: 0;
    height: auto;
    width: 3px;
    border-radius: 3px 0 0 3px;
  }
  
  /**Card icon*/
  .card-icon {
    width: 3rem;
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
  }
  
  /**Card alert*/
  .card-alert {
    border-radius: 0;
    margin: -1px -1px 0;
  }
  
  .card-category {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.05em;
    margin: 0 0 0.5rem;
    background: #f3f3f3;
  }
  
  .card-custom-icon {
    position: absolute;
    right: 25px;
    top: 15px;
    color: #e5e9f2;
    width: 60px;
    height: 60px;
  }
  

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
  .avatar-edit {
      position: absolute;
      right: 12px;
      z-index: 1;
      top: 10px;
      input {
          display: none;
          + label {
              display: inline-block;
              width: 34px;
              height: 34px;
              margin-bottom: 0;
              border-radius: 100%;
              background: #FFFFFF;
              border: 1px solid transparent;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
              cursor: pointer;
              font-weight: normal;
              transition: all .2s ease-in-out;
              &:hover {
                  background: #f1f1f1;
                  border-color: #d6d6d6;
              }
              &:after {
                  content: "\f040";
                  font-family: 'FontAwesome';
                  color: #757575;
                  position: absolute;
                  top: 10px;
                  left: 0;
                  right: 0;
                  text-align: center;
                  margin: auto;
              }
          }
      }
  }
  .avatar-preview {
      width: 192px;
      height: 192px;
      position: relative;
      border-radius: 100%;
      border: 6px solid #F8F8F8;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      > div {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
      }
  }
}

.avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  > img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
  }
}
.ag-theme-alpine {
  /* bright green, 10% opacity */
  --ag-selected-row-background-color: rgb(0, 255, 0, 0.1);
  height: 800px !important;
  --ag-header-height: 30px;
  --ag-header-foreground-color: white;
  --ag-header-background-color: black;
  --ag-header-cell-hover-background-color: rgb(80, 40, 140);
  --ag-header-cell-moving-background-color: rgb(80, 40, 140);
}

.pagination{
  .active a{
    color:#143361;
    background-color: transparent;
    font-weight:600;
    &:hover{
      background-color: transparent;
      color:#143361;
      border:0px;
    }
    &:focus{
      background-color:transparent;
      color:#143361;
      outline:none;
    }
  }
  li a{
    border:1px;
    margin-left:0px;
    color:#707070;
    padding:7px 2px;
    margin:0px 20px;
    &:hover{
      background-color:transparent;
      color:#4A90E2;
      padding-bottom: 2px;
      border-bottom: 1px solid;
      //padding: 1px;
      //margin: 5px 25px;
    }
    &:focus{
      outline:none;
      background-color: transparent;
      /*color:#707070;*/
    }
  }
  li:first-child a,li:last-child a{
    border:2px solid #143361 !important;
    border-radius: 6px;
    margin:0px;
    padding:6px 12px;
    border: 2px solid;
    font-size:14px;
    color:#143361;
    &:hover{ text-decoration:none !important;
    color: #fff;
    background-color: #143361;
  }
    &:focus{
      outline:none;
    }
  }
}


.btn-primary {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}
.form-submit{
padding: 13px 30px;
font-size: 15px;
letter-spacing: 0.3px;
font-weight: 400;
}
.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.kb-file-upload {
  margin-bottom: 20px;
}
.file-upload-box {
  border: 1px dashed #b6bed1;
  background-color: #f0f2f7;
  border-radius: 4px;
  min-height: 150px;
  position: relative;
  overflow: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  font-size: 15px;
}
.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link{
  color: #475f7b;
  text-decoration: underline;
  margin-left: 3px;
}
.file-upload-box .file-link:hover{
  text-decoration: none;
}
.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.file-image {
  /* width: 130px;
  min-width: 130px;
  height: 85px;
  min-height: 85px; */
  width: 130px;
  height: 85px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img{
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}
.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-detail p {
  font-size: 12px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 12px;
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
.file-atc-box:last-child{
  margin-bottom: 0;
}



.custom-react-player .download-button {
  display: none !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none; /* This allows interactions with elements beneath the overlay */
}