
img {
    max-width: 100%;
  }
  
  .main-container h1 {
    display: block;
    font-size: 24px;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: #121926;
}

.main-container h6 {
  font-size: 12px;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #121926;
}
  
  .netflixLogo {
    grid-area: nt;
    object-fit: cover;
    width: 100px;
    max-height: 100%;
    
    padding-left: 30px;
    padding-top: 0px;  
  }
  
  .netflixLogo img {  
    height: 35px;     
  }
  
  #logo {
    color: #E50914;  
    margin: 0; 
    padding: 0; 
  }
  
  

  

  
  .box {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
   
  }
  
  .box a {
    transition: transform .3s;  
  }
  
  .box img:hover {
    transition: transform .3s;
    -ms-transform: scale(1.4);
    -webkit-transform: scale(1.4);  
    transform: scale(1.4);
  }
  
  .box img {
    border-radius: 2px;
  }
  
  /* LINKS */
  .link {
    padding: 50px;
  }
  
  .sub-links ul {
    list-style: none;
    padding: 0;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
  }
  
  .sub-links a {
    color: var(--dark);
    text-decoration: none;
  }
  
  .sub-links a:hover {
    color: var(--dark);
    text-decoration: underline;
  }
  
  .logos a{
    padding: 10px;
  }
  
  .logo {
    color: var(--dark);
  }
  

  /* MEDIA QUERIES */
  
  @media(max-width: 900px) {
  
   
  
    .box {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(4, minmax(100px, 1fr));
    }
  
  }
  
  @media(max-width: 700px) {
  
   
  
    .box {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
  
    .sub-links ul {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(3, 1fr);
    }
     
  }
  
  @media(max-width: 500px) {
  
    .wrapper {
      font-size: 15px;
    }
  
   
    .netflixLogo {
      max-width: 100%;
      margin: auto;
      padding-right: 20px;
    }
  
    .main-nav {
      display: grid;
      grid-gap: 0px;
      grid-template-columns: repeat(1, 1fr);
      text-align: center;
    }
  
    h1 {
      text-align: center;
      font-size: 18px;
    }
  
   
  
    .box {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(1, 1fr);
      text-align: center;    
    }
  
    .box a:hover {
      transition: transform .3s;
      -ms-transform: scale(1);
      -webkit-transform: scale(1);  
      transform: scale(1.2);
    }
  
    .logos {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
      text-align: center;
    }
  
    .sub-links ul {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(1, 1fr);
      text-align: center;
      font-size: 15px;
    }
  
    
  
    
     
  }

  /* Style for the video player container */
.video-player-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Set an appropriate height for the video container */
  background-color: #000; /* Background color for the video container */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping */
  scroll-behavior: smooth; /* Add smooth scrolling behavior */
}

/* Style for the video element itself */
video {
  max-width: 100%;
  height: auto; /* Maintain the video's aspect ratio */
  border: 1px solid #ccc; /* Add a border to the video */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
}
.video-container {
  position: relative;
  display:none;
}

.custom-react-player .download-button {
  display: none !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none; /* This allows interactions with elements beneath the overlay */
}

/* CSS to hide the download button */
.download-button {
  display: none !important;
}
video::-internal-media-controls-download-button {
  display:none;
}

video::-webkit-media-controls-enclosure {
  overflow:hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}
